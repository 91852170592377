import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { formatDate } from '@/pages/CustomerDashboard/helpers';
import { PointsTag } from '@/pages/CustomerDashboard/PointsTag';
import { ApiPromotion } from '@api/interfaces';
import { Stack, useTheme } from '@mui/material';
import { SponsorType } from '@shared/enums';
import { getJsDateFromString } from '@shared/utilities';
import React from 'react';

interface PromotionCardProps {
  listItem?: boolean,
  onClickDetails: () => void,
  promotion: ApiPromotion,
  width?: string | number,
}

export const PromotionCard = ({
  listItem = false,
  onClickDetails,
  promotion,
  width = '252px',
}: PromotionCardProps) => {
  const theme = useTheme();
  const manufacturerName = promotion.manufacturer?.name ?? '';
  return (
    <Stack
      bgcolor={theme.palette.background.default}
      borderRadius="8px"
      boxShadow={listItem
        ? '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
        : undefined
      }
      minWidth="252px"
      p="16px"
      width={width}
    >
      <Stack flexGrow={listItem ? undefined : '1'}>
        {promotion.sponsorType === SponsorType.Manufacturer && (
          <>
            <Text category="overline">{manufacturerName} Promotion</Text>
            <VSpacer size="2" />
          </>
        )}
        <Text
          fontSize="24px"
          fontWeight={500}
          lineHeight={1.25}
          sx={{ hyphens: 'auto', wordBreak: 'break-word' }}
        >
          {promotion.name}
        </Text>
        <VSpacer size="3"/>
        <PointsTag points={promotion.value} rewardsType={promotion.rewardType} variant="filled" />
      </Stack>
      <VSpacer size="3" />
      <Stack
        alignItems={listItem ? 'flex-end' : 'center'}
        direction={listItem ? 'row-reverse' : 'column'}
        justifyContent={listItem ? 'space-between' : undefined}
      >
        <Button
          onClick={onClickDetails}
          sx={{
            bgcolor: theme.palette.grey[100],
            boxShadow: 'none',
            '&:hover': { bgcolor: theme.palette.grey[100] },
            color: theme.palette.text.primary,
          }}
          testID={`dashboard-promotion-${promotion.id}-button`}
        >
          Details
        </Button>
        {!listItem && (
          <VSpacer size="4"/>
        )}
        <Text category="body-small">
          Expires {formatDate(getJsDateFromString(promotion.endDate))}
        </Text>
      </Stack>
    </Stack>
  );
};
