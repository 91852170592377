import { ResolvedOrderTransaction } from '@/pages/CustomerDashboard/interfaces';
import { Stack, useTheme } from '@mui/material';
import { formatDate, formatPoints } from '@/pages/CustomerDashboard/helpers';
import { TransactionType } from '@shared/enums';
import Star from '@mui/icons-material/Star';
import { HSpacer, Text } from '@/components/DesignSystem';
import Add from '@mui/icons-material/Add';
import { Remove } from '@mui/icons-material';
import React from 'react';
import { ApiUser } from '@api/interfaces';
import { getAccountName } from '@shared/utilities/UserUtilities';

interface RewardsActivityCardProps {
  customer?: ApiUser,
  order: ResolvedOrderTransaction,
}

export const RewardsActivityCard = ({
  customer,
  order,
}: RewardsActivityCardProps) => {
  const theme = useTheme();
  const formattedDate = formatDate(order.orderDate);
  const histories = order.products.flatMap((product) => product.userLoyaltyPointsHistories);

  let formattedTotal = `$${formatPoints(Math.abs(order.totalCost))}`;
  if (order.totalCost < 0) {
    formattedTotal = `-${formattedTotal}`;
  }

  let pointsRedeemed = 0;
  let pointsEarned = 0;
  let pointsReturned = 0;
  histories.forEach((history) => {
    switch (history.type) {
      case TransactionType.CREDIT:
        pointsEarned += history.amount;
        break;
      case TransactionType.CORRECTION:
        pointsEarned -= history.amount;
        break;
      case TransactionType.DEBIT:
        pointsRedeemed += history.amount;
        break;
      case TransactionType.RETURN:
        pointsReturned += history.amount;
        break;
    }
  });

  const StarIcon = (
    <Star sx={{ height: '12px', width: '12px' }}/>
  );

  return (
    <Stack py="16px">
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Text category="title-medium" sx={{ whiteSpace: 'nowrap' }}>
            {formattedDate}
          </Text>
          {!!customer && (
            <Text breakWord category="body-medium">
              {getAccountName(customer)}
            </Text>
          )}
          <Text breakWord category="body-small" sx={{ color: theme.palette.grey[800] }}>
            Order {order.externalId}
          </Text>
        </Stack>
        <HSpacer size="3" />
        <Stack alignItems="end" >
          <Text category="title-medium">
            {formattedTotal}
          </Text>
          {pointsRedeemed > 0 && (
            <Stack alignItems="center" color="#C31515" direction="row" justifyContent="end">
              <Remove sx={{ height: '12px', width: '12px' }}/>
              <Text category="body-medium">
                {formatPoints(pointsRedeemed)}
              </Text>
              <HSpacer size="2"/>
              {StarIcon}
              <HSpacer size="2"/>
              <Text category="body-medium">
                redeemed
              </Text>
            </Stack>
          )}
          {pointsEarned > 0 && (
            <Stack alignItems="center" color="#389E0D" direction="row">
              <Add sx={{ height: '12px', width: '12px' }}/>
              <Text category="body-medium">
                {formatPoints(pointsEarned)}
              </Text>
              <HSpacer size="2"/>
              {StarIcon}
              <HSpacer size="2"/>
              <Text category="body-medium">
                earned
              </Text>
            </Stack>
          )}
          {pointsReturned > 0 && (
            <Stack alignItems="center" direction="row" justifyContent="end">
              <Remove sx={{ height: '12px', width: '12px' }}/>
              <Text category="body-medium">
                {formatPoints(pointsReturned)}
              </Text>
              <HSpacer size="2"/>
              {StarIcon}
              <HSpacer size="2"/>
              <Text category="body-medium">
                returned
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
