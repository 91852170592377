import { equalsIgnoreCase } from "../utilities";

export enum RewardsUom {
  Acres = 'ac',
  Bags = 'bags',
  Bushels = 'bu',
  Dollars = '$',
  Gallons = 'gal',
  Tons = 'ton',
}

export const RewardUomMapping: Record<RewardsUom, string[]> = {
  [RewardsUom.Acres]: [RewardsUom.Acres, 'acres'],
  [RewardsUom.Bags]: [RewardsUom.Bags, 'bg', 'bag'],
  [RewardsUom.Bushels]: [RewardsUom.Bushels, 'bushel', 'bushels'],
  [RewardsUom.Dollars]: ['dollars'],
  [RewardsUom.Gallons]: [RewardsUom.Gallons, 'ga', 'gallon', 'gallons'],
  [RewardsUom.Tons]: [RewardsUom.Tons, 'tn', 'tons'],
};

export const parseRewardsUom = (uom: string | null): RewardsUom | null => {
  if (uom === null) {
    return null;
  }
  // Handle this case separately since $ is a special character in regex.
  if (uom === RewardsUom.Dollars) {
    return RewardsUom.Dollars;
  }
  const rewardsUomValues: RewardsUom[] = Object.values(RewardsUom);
  for (const rewardUom of rewardsUomValues) {
    const mappedUomValues = RewardUomMapping[rewardUom];
    const rewardMappingRegex = new RegExp(`^(${mappedUomValues.join('|')})$`, 'i');
    if (rewardMappingRegex.test(uom)) {
      return rewardUom as RewardsUom;
    }
  }
  return null;
};

export namespace RewardUomMappingLookup {
  export function lookup (input: string): RewardsUom | null {
    const uoms = Object.values(RewardsUom);
    for (const uom of uoms) {
      const options = RewardUomMapping[uom];
      for (const option of options) {
        if (equalsIgnoreCase(option, input)) {
          return uom;
        }
      }
    }

    return null;
  }
}
