import {
  Button,
  Checkbox,
  DataPoint,
  DatePickerDropdown,
  HSpacer,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Select,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { InfoTooltip } from '@/components/DesignSystem/InfoTooltip/InfoTooltip';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import { FileUpload } from '@/components/shared/FileUpload';
import { QueryKeys } from '@/constants/QueryKeys';
import { useColor } from '@/hooks/useColor';
import {
  DeleteBenefitConfirmDialog,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/DeleteBenefitConfirmDialog';
import {
  RewardsTierBenefitModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsTierBenefitModal';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RewardsProgramApi } from "@/utilities/api/RewardsProgramApi";
import { getImageAsDataURL } from '@/utilities/Image';
import { RewardsProgramEndpoint } from '@api/endpoints/RewardsProgramEndpoint';
import { ApiCategory } from '@api/interfaces';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import AddIcon from '@mui/icons-material/Add';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Edit from '@mui/icons-material/Edit';
import { Box, Divider, Stack } from '@mui/material';
import { RewardsType } from '@shared/enums/RewardsType';
import { RewardsUom } from '@shared/enums/RewardsUom';
import {
  formatCurrency,
  formatDateOnly,
  getDifferenceInDays,
  getJsDateFromString,
  roundToFixed,
  sanitizedFileName,
} from '@shared/utilities';
import { isEqual } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { formatDate } from './helpers';
import { RewardsTiersModal } from './RewardsTiersModal';

const DefaultProgramName = 'Rewards & Loyalty Program';

export type SubcategoryRewards = Record<
  string,
  {
    hideOnDashboard: boolean,
    loyaltyUom: RewardsUom,
    rewardsType: RewardsType,
    rewardsValue: number,
  }
>;

export type Benefit = {
  description: string,
  name: string,
  termsUrl: string | null,
  tier: Tier,
}

type Program = {
  endDate: string | null,
  grantBothProgramAndPromotionPoints: boolean,
  name: string,
  startDate: string | null,
  tiers: Tier[],
}

export type Tier = {
  minimumDollars: number,
  minimumSegments: number,
  name: string,
  segments: Segment[],
}

export type Segment = {
  categoryId: string,
  hideOnDashboard: boolean,
  loyaltyUom: RewardsUom | null,
  minimumHurdle: number,
  rewardsType: RewardsType | null,
  rewardsValue: number | null,
  subcategoryRewards: SubcategoryRewards | null,
  uom: RewardsUom,
}

interface RewardsProgramModalProps {
  existingProgram?: ApiRewardsProgram,
  onClose: () => void,
  onCreate: (rewardsProgram: Omit<RewardsProgramEndpoint.Create.Request, 'retailerId'>) => void,
  onUpdate: (updates: RewardsProgramEndpoint.Update.Request) => void,
  retailerId: string,
}

export const RewardsProgramModal = ({
  existingProgram,
  onClose,
  onCreate,
  onUpdate,
  retailerId,
}: RewardsProgramModalProps) => {
  const [showRewardsTiersModal, setShowRewardsTiersModal] = useState(false);
  const [showRewardsTierBenefitsModal, setShowRewardsTierBenefitsModal] = useState(false);
  const [showDeleteBenefitDialog, setShowDeleteBenefitDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<ApiCategory[]>([]);
  const [initialSelectedCategories, setInitialSelectedCategories] = useState<ApiCategory[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [imageError, setImageError] = useState<string>('');
  const [uploadedImage, setUploadedImage] = useState<File | null | undefined>();
  const [program, setProgram] = useState<Program>({
    endDate: existingProgram?.endDate ?? null,
    grantBothProgramAndPromotionPoints:
      existingProgram?.grantBothProgramAndPromotionPoints ?? false,
    name: existingProgram?.name ?? DefaultProgramName,
    startDate: existingProgram?.startDate ?? null,
    tiers: existingProgram?.tiers?.map((tier) => (
      { ...tier, segments: tier.segments ?? [] }
    )) ?? [],
  });

  const { data: hasActiveUsers, isFetching: isCheckingForActiveUsers } = useQuery(
    [QueryKeys.GET_REWARDS_PROGRAM_HAS_ACTIVE_USERS, existingProgram?.id],
    () => RewardsProgramApi.hasActiveUsers(existingProgram?.id ?? ''),
    { enabled: !!existingProgram?.id },
  );

  const [benefitToEdit, setBenefitToEdit] = useState<Benefit>();
  const existingBenefits = existingProgram?.tiers?.flatMap(
    (tier) => (tier.benefits ?? []).map((benefit) => ({ ...benefit, tier })),
  ) ?? [];

  const [initialBenefits] = useState(existingBenefits);
  const [benefits, setBenefits] = useState<Benefit[]>(existingBenefits?.map((benefit) => ({
    ...benefit,
    tier: { ...benefit.tier, segments: benefit.tier.segments ?? [] },
  })) ?? []);
  const [existingProgramImagePath, setExistingProgramImagePath] = (
    useState(existingProgram?.campaignImage)
  );
  const { getColor } = useColor();

  const isViewing = !!existingProgram && !isEditing;
  const canEdit = (
    !!existingProgram?.endDate
    && existingProgram?.endDate >= formatDateOnly(new Date())
  );
  const tiersWithSelectedCategories = program.tiers.map((tier) => ({
    ...tier,
    segments: tier.segments
      .filter(({ categoryId }) => (
        selectedCategories.map(({ id }) => id).includes(categoryId)
      ))
      .map((segment) => {
        const newSegment = {
          ...segment,
          subcategoryRewards: segment.subcategoryRewards
            ? Object.fromEntries(
              Object.entries(segment.subcategoryRewards).filter(([subcategoryId, _]) => (
                selectedCategories.map(({ id }) => id).includes(subcategoryId)
              )),
            )
            : null,
        };
        if (
          newSegment.subcategoryRewards
          && Object.keys(newSegment.subcategoryRewards).length === 0
        ) {
          newSegment.subcategoryRewards = null;
        }
        return newSegment;
      }),
  }));

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, retailerId],
    async () => {
      const categories = await HierarchyOfRetailersApi.getRetailerCategories(retailerId);
      let initialSelectedCategories: ApiCategory[] = categories;
      if (existingProgram) {
        const categoryIds = [...new Set(existingProgram.tiers?.flatMap(
          (tier) => tier.segments?.flatMap((segment) => (
            [segment.categoryId, ...Object.keys(segment.subcategoryRewards ?? {})]
          )),
        ))] ?? [];
        initialSelectedCategories = categories.filter(
          (category) => categoryIds.includes(category.id),
        );
      }
      setInitialSelectedCategories(initialSelectedCategories);
      setSelectedCategories(initialSelectedCategories);
      return categories;
    },
  );

  const dateError = useMemo(() => {
    const startDate = program.startDate;
    const endDate = program.endDate;
    if (!endDate || !startDate) {
      return;
    }
    if (startDate >= endDate) {
      return 'Start date must be before end date';
    }
    if (getDifferenceInDays(new Date(startDate), new Date(endDate)) < 365) {
      return 'Program duration must be at least 12 months';
    }
  }, [program.endDate, program.startDate]);
  const startJsDate = program.startDate
    ? getJsDateFromString(program.startDate)
    : undefined;
  const endJsDate = program.endDate
    ? getJsDateFromString(program.endDate)
    : undefined;

  const areBenefitsChanged = !isEqual(initialBenefits, benefits);
  const areSelectedCategoriesChanged = !isEqual(initialSelectedCategories, selectedCategories);
  const areTiersChanged = !!existingProgram?.tiers && program.tiers.some((tier) => {
    const existingTier = existingProgram.tiers!.find((t) => t.name === tier.name);
    return !existingTier
      || existingTier.minimumDollars !== tier.minimumDollars
      || existingTier.minimumSegments !== tier.minimumSegments
      || !isEqual(existingTier.segments, tier.segments)
      || areSelectedCategoriesChanged;
  });
  const isImageChanged =
    (!!existingProgram?.campaignImage && (!!uploadedImage || uploadedImage === null))
    || (!existingProgram?.campaignImage && !!uploadedImage)
    || (!!existingProgram?.campaignImage && !existingProgramImagePath);
  const isChanged = (
    program.name !== existingProgram?.name
    || program.endDate !== existingProgram?.endDate
    || program.startDate !== existingProgram?.startDate
    || program.grantBothProgramAndPromotionPoints
      !== existingProgram?.grantBothProgramAndPromotionPoints
    || areTiersChanged
    || areBenefitsChanged
    || areSelectedCategoriesChanged
    || isImageChanged
  );
  const categoriesInTiers = program.tiers
    .flatMap((tier) => tier.segments)
    .flatMap(({ categoryId, subcategoryRewards }) => (
      [categoryId, ...Object.keys(subcategoryRewards ?? {})]
    ));
  const areAllSelectedCategoriesIncluded = selectedCategories.every((category) => (
    categoriesInTiers.includes(category.id)
  ));
  const areRewardsValid = program.tiers.every(({ segments }) => (
    segments.every(({ rewardsType, rewardsValue, subcategoryRewards }) => (
      !((rewardsType !== null) !== (rewardsValue !== null))
      && !(rewardsType !== null && !!subcategoryRewards)
    ))
  ));
  const isValid = (
    !!program.name
    && !!program.endDate
    && !!program.startDate
    && !dateError
    && program.tiers.length > 0
    && selectedCategories.length > 0
    && isChanged
    && areAllSelectedCategoriesIncluded
    && areRewardsValid
  );

  const handleImageChange = async (file: File | undefined) => {
    setImageError('');
    if (file) {
      try {
        await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
              if (image.width > 360 || image.height > 60) {
                reject('Image must not be larger than 30 x 360 pixels');
              } else {
                setImageError('');
                resolve(getImageAsDataURL(image));
              }
              resolve(reader.result as string);
            };
          };
          reader.readAsDataURL(file as Blob);
        });
      } catch (error) {
        setImageError(error as string);
        return;
      }
      setUploadedImage(file);
      setImageError('');
    } else {
      if (existingProgramImagePath) {
        setExistingProgramImagePath(null);
      }
    }
  };

  const getImageAsString = async () => {
    let imageBase64: string | null = null;
    let imageFilename: string | null = null;
    if (uploadedImage) {
      imageBase64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const imageDataUrl = getImageAsDataURL(img);
            resolve(imageDataUrl);
          };
        };
        reader.readAsDataURL(uploadedImage as Blob);
      });
      imageFilename = sanitizedFileName(uploadedImage.name);
    }
    return imageBase64 ? `${imageFilename}+${imageBase64}` : null;
  };

  const handleSave = async () => {
    onClose();
    if (isEditing) {
      const updateBody: RewardsProgramEndpoint.Update.Request = {};
      if (program.name !== existingProgram?.name) {
        updateBody.name = program.name;
      }
      if (program.startDate !== existingProgram?.startDate) {
        updateBody.startDate = program.startDate!;
      }
      if (program.endDate !== existingProgram?.endDate) {
        updateBody.endDate = program.endDate!;
      }
      if (program.grantBothProgramAndPromotionPoints
        !== existingProgram?.grantBothProgramAndPromotionPoints) {
        updateBody.grantBothProgramAndPromotionPoints = program.grantBothProgramAndPromotionPoints;
      }
      if (areTiersChanged) {
        if (areSelectedCategoriesChanged) {
          const selectedSegmentIds = selectedCategories.map((category) => category.id);
          program.tiers.forEach((tier) => {
            tier.segments = tier.segments
              .filter((segment) => (
                selectedSegmentIds.includes(segment.categoryId)
              ))
              .map((segment) => ({
                ...segment,
                subcategoryRewards: segment.subcategoryRewards
                  ? Object.fromEntries(
                    Object.entries(segment.subcategoryRewards).filter(([subcategoryId, _]) => (
                      selectedSegmentIds.includes(subcategoryId)
                    ),
                    ))
                  : null,
              }));
          });
        }
        updateBody.tiers = program.tiers as RewardsProgramEndpoint.Create.Tier[];
      }
      if (areBenefitsChanged) {
        const tiers = program.tiers.map((tier) => {
          const tierBenefits = benefits.filter((benefit) => benefit.tier.name === tier.name);
          return { ...tier, benefits: tierBenefits };
        });
        updateBody.tiers = tiers as RewardsProgramEndpoint.Create.Tier[];
      }
      if (isImageChanged) {
        updateBody.campaignImage = await getImageAsString();
      }
      onUpdate(updateBody);
    } else {
      const tiers = tiersWithSelectedCategories.map((tier) => {
        const tierBenefits = benefits.filter((benefit) => benefit.name === tier.name);
        return {
          ...tier,
          benefits: tierBenefits,
          segments: tier.segments as RewardsProgramEndpoint.Create.Segment[],
        };
      });
      const createBody = {
        campaignImage: uploadedImage ? await getImageAsString() : undefined,
        grantBothProgramAndPromotionPoints: program.grantBothProgramAndPromotionPoints,
        endDate: program.endDate!,
        name: program.name,
        startDate: program.startDate!,
        tiers,
      };
      onCreate(createBody);
    }
  };

  const DateInputs = (
    (isViewing || hasActiveUsers) ? (
      <Stack direction="row">
        <Stack>
          <Text category="label-large">
            Start Date
          </Text>
          <VSpacer size="2" />
          <Text category="body-large">
            {formatDate(startJsDate!)}
          </Text>
        </Stack>
        <HSpacer size="7" />
        <Stack>
          <Text category="label-large">
            End Date
          </Text>
          <VSpacer size="2" />
          <Text category="body-large">
            {formatDate(endJsDate!)}
          </Text>
        </Stack>
      </Stack>
    ) : (
      <Stack direction="row" width={464}>
        <DatePickerDropdown
          errorMessage={dateError}
          label="Start Date"
          onChange={(date) => (
            setProgram({ ...program, startDate: date ? formatDateOnly(date) : null })
          )}
          testID="start-date-input"
          value={startJsDate}
        />
        <HSpacer size="7" />
        <DatePickerDropdown
          errorMessage={dateError && ' '}
          label="End Date"
          onChange={(date) => (
            setProgram({ ...program, endDate: date ? formatDateOnly(date) : null })
          )}
          testID="end-date-input"
          value={endJsDate}
        />
      </Stack>
    )
  );

  const getCategoryName = (categoryId: string) => (
    categories?.find(({ id }) => id === categoryId)?.name
  );

  const TierBenefitsTable = () => {
    if (!categories) {
      return null;
    }
    return (
      <>
        <Table
          headers={['Tier Name', 'Benefit Title', 'Benefit Description', '']}
        >
          {benefits.map((benefit) => (
            <TableRow
              key={benefit.name}
              values={[
                benefit.tier.name,
                benefit.name,
                benefit.description,
                <Stack direction="row" justifyContent="flex-end" key={`${benefit.name}-actions`}>
                  {!!benefit.termsUrl && (
                    <>
                      <TextLink
                        href={benefit.termsUrl}
                        sx={{ color: getColor('info', 5) }}
                        target='_blank'
                        testID={`${benefit.name}-terms-url`}
                      >
                        Terms
                      </TextLink>
                      <HSpacer size="12" />
                    </>
                  )}
                  <Box width="96px">
                    {isViewing ? null : (
                      <IconButton
                        color="inherit"
                        key={`${benefit.name}-edit`}
                        onClick={() => {
                          setShowRewardsTierBenefitsModal(true);
                          setBenefitToEdit(benefit);
                        }}
                        testID={`${benefit.name}-edit-button`}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {isViewing ? null : (
                      <IconButton
                        color="inherit"
                        key={`${benefit.name}-delete`}
                        onClick={() => {
                          setBenefitToEdit(benefit);
                          setShowDeleteBenefitDialog(true);
                        }}
                        testID={`${benefit.name}-delete-button`}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Box>
                </Stack>,
              ]}
            />
          ))}
        </Table>
        {benefits.length === 0 && (
          <Box
            alignItems="center"
            bgcolor="#1B1B1B"
            display="flex"
            justifyContent="center"
            py="64px"
          >
            <Text category="body-xlarge">
              Add custom benefits per each rewards tier
            </Text>
          </Box>
        )}
      </>
    );
  };

  const TierTable = (
    !!categories && (
      <>
        <Table
          headers={['Tier', 'Min. Total Spend', 'Min. Total Segments', 'Dashboard']}
        >
          {tiersWithSelectedCategories.map((tier) => (
            <TableRow
              expandedComponent={!tier.segments.length ? undefined : tier.segments.map(({
                categoryId,
                hideOnDashboard,
                loyaltyUom,
                minimumHurdle,
                rewardsType,
                rewardsValue,
                subcategoryRewards,
                uom,
              }) => {
                const loyaltyText = rewardsValue && (
                  rewardsType === RewardsType.Dollars
                    ? `$${roundToFixed(rewardsValue, 4)} per ${
                      loyaltyUom === RewardsUom.Dollars ? '$1' : loyaltyUom
                    }`
                    : `${roundToFixed(rewardsValue * 100, 3)}% per $1`
                );
                const hurdleText = uom === RewardsUom.Dollars
                  ? `$${roundToFixed(minimumHurdle, 2)}`
                  : `${minimumHurdle} ${uom}`;
                return (
                  <TableRow
                    expandedComponent={!subcategoryRewards ? undefined : (
                      Object.entries(subcategoryRewards).map(([subcategoryId, rewards]) => {
                        const loyaltyText = rewards.rewardsType === RewardsType.Dollars
                          ? `$${roundToFixed(rewards.rewardsValue!, 4)} per ${
                            rewards.loyaltyUom === RewardsUom.Dollars ? '$1' : rewards.loyaltyUom
                          }`
                          : `${roundToFixed(rewards.rewardsValue! * 100, 3)} %`;
                        return (
                          <TableRow
                            key={subcategoryId}
                            values={[
                              '',
                              <Text category="body-medium" key="name">
                                {getCategoryName(subcategoryId)}
                              </Text>,
                              '',
                              <Stack key="loyalty">
                                {!!rewards.rewardsType && rewards.rewardsValue !== null && (
                                  <Text category="body-medium">
                                    {loyaltyText}
                                  </Text>
                                )}
                              </Stack>,
                              rewards.hideOnDashboard ? 'Hide' : 'Show',
                            ]}
                          />
                        );
                      })
                    )}
                    key={categoryId}
                    values={[
                      ...(subcategoryRewards ? [] : ['']),
                      <Text category="body-medium" key="name">
                        {getCategoryName(categoryId)}
                      </Text>,
                      <Stack key="hurdle">
                        <Text category="label-small">
                          Min hurdle
                        </Text>
                        <VSpacer size="1" />
                        <Text category="body-medium">
                          {hurdleText}
                        </Text>
                      </Stack>,
                      <Stack key="loyalty">
                        <Text category="label-small">
                          Loyalty Value
                        </Text>
                        {!!rewardsType && rewardsValue !== null &&  (
                          <>
                            <VSpacer size="1" />
                            <Text category="body-medium">
                              {loyaltyText}
                            </Text>
                          </>
                        )}
                      </Stack>,
                      (hideOnDashboard || (
                        subcategoryRewards && Object.values(subcategoryRewards)?.every(
                          (rewards) => rewards.hideOnDashboard,
                        ))
                      ) ? 'Hide' : 'Show',
                    ]}
                  />
                );
              })}
              key={tier.name}
              values={[
                tier.name,
                formatCurrency(tier.minimumDollars),
                tier.minimumSegments,
                '',
              ]}
            />
          ))}
        </Table>
        {program.tiers.length === 0 && (
          <Box
            alignItems="center"
            bgcolor="#1B1B1B"
            display="flex"
            justifyContent="center"
            py="64px"
          >
            <Text category="body-xlarge">
              Add a tier to create the rewards program
            </Text>
          </Box>
        )}
      </>
    )
  );

  const parentCategories = categories?.filter(({ parentId }) => !parentId);
  const orderedCategories = parentCategories?.flatMap((parent) => {
    const children = categories!.filter(({ parentId }) => parentId === parent.id);
    return [parent, ...children];
  });

  const CategorySelect = (
    <Select
      disabled={isViewing || hasActiveUsers}
      label="Segments"
      multiple
      onChange={(e) => {
        if (categories) {
          const categoryIds = e.target.value as string[];
          const selectedCategoryIds = selectedCategories.map(({ id }) => id);
          const isRemoval = categoryIds.length < selectedCategories.length;
          const categoryIdToUpdate = isRemoval
            ? selectedCategoryIds.find((id) => !categoryIds.includes(id))!
            : categoryIds.find((id) => !selectedCategoryIds.includes(id))!;
          const categoryToUpdate = categories.find(({ id }) => id === categoryIdToUpdate)!;
          const isSubcategory = !!categoryToUpdate.parentId;
          const isParentSelected = (
            isSubcategory
            && selectedCategoryIds.includes(categoryToUpdate.parentId!)
          );
          let newSelectedCategoryIds = [...categoryIds];

          if (isSubcategory) {
            if (!isRemoval && !isParentSelected) {
              newSelectedCategoryIds.push(categoryToUpdate.parentId!);
            }
          } else {
            const subcategories = categories.filter(({ parentId }) => (
              parentId === categoryIdToUpdate
            ));
            if (isRemoval) {
              newSelectedCategoryIds = newSelectedCategoryIds.filter((id) => (
                !subcategories.map(({ id }) => id).includes(id)),
              );
            } else {
              newSelectedCategoryIds.push(...subcategories.map(({ id }) => id));
            }
          }
          const newSelectedCategories = categories.filter(({ id }) => (
            newSelectedCategoryIds.includes(id)
          ));
          setSelectedCategories(newSelectedCategories.sort(
            (a, b) => (a.name ?? '').localeCompare(b.name ?? '')),
          );
        }
      }}
      placeholder={selectedCategories.map((category) => category.name).join(', ')}
      renderValue={() => selectedCategories.map((category) => category.name).sort().join(', ')}
      testID="segments-select"
      value={selectedCategories.map(({ id }) => id)}
      width={464}
    >
      {(orderedCategories ?? []).map((category) => (
        <MenuItem
          key={category.id}
          testID={`${category.id}-item`}
          value={category.id}
        >
          {!!category.parentId && (
            <HSpacer size="8" />
          )}
          <Checkbox
            checked={selectedCategories.map(({ id }) => id).includes(category.id)}
            testID={`segments-select-${category.id}-checkbox`}
          />
          {category.name}
        </MenuItem>
      ))}
    </Select>
  );

  let modalTitle: string;
  if (isEditing) {
    modalTitle = 'Edit Program';
  } else if (isViewing) {
    modalTitle = 'View Program';
  } else {
    modalTitle = 'Add Program';
  }

  return (
    <>
      <Modal
        acceptButton={!isViewing ? (props) => (
          <Button
            {...props}
            disabled={!isValid}
            onClick={handleSave}
            testID="rewards-program-modal-save-button"
          >
            Save
          </Button>
        ) : undefined}
        cancelButton={(props) => (
          <Button
            {...props}
            color="inherit"
            onClick={onClose}
            testID="rewards-program-modal-cancel-button"
          >
            {isViewing ? 'Close' : 'Cancel'}
          </Button>
        )}
        headerAccessoryRight={canEdit && !isEditing && (
          <Button
            disabled={isCheckingForActiveUsers}
            onClick={() => setIsEditing(true)}
            startIcon={<Edit />}
            sx={{ paddingX: '20px' }}
            testID="rewards-program-modal-edit-button"
          >
            Edit
          </Button>
        )}
        largeModal
        onClose={onClose}
        open
        testID="rewards-program-modal"
        title={modalTitle}
      >
        <Text>
          Define the rewards program that is being offered by this retailer to their customers.
          The program will restart at the end of each period. Customers must re-earn the tier that
          they are currently in each period or will be moved to the tier that they have earned at
          the end of the period.
        </Text>
        <VSpacer size="8" />
        <Stack direction="row">
          <Text category="title-medium">
            Campaign Name
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Customize the name of the retailer rewards program
          </InfoTooltip>
        </Stack>
        <VSpacer size="5" />
        <Input
          disabled={isViewing}
          label="Campaign Name"
          onChangeText={(name) => setProgram({ ...program, name })}
          testID="rewards-program-name-input"
          value={program.name}
          width={464}
        />
        <VSpacer size="9" />
        <Text category="title-medium">
          Campaign Image
        </Text>
        <VSpacer size="4" />
        <Stack direction="row" gap="16px">
          <DataPoint label="File type:" py={0} testID="file-type-datapoint">
            PNG, SVG, JPG, JPEG
          </DataPoint>
          <DataPoint label="Max Height:" py={0} testID="max-height-datapoint">60px</DataPoint>
          <DataPoint label="Max Width:" py={0} testID="max-width-datapoint">360px</DataPoint>
          <DataPoint label="Max file size:" py={0} testID="max-file-size-datapoint">5MB</DataPoint>
        </Stack>
        {!!imageError && (
          <Text color="error">
            {imageError}
          </Text>
        )}
        <VSpacer size="4" />
        <FileUpload
          acceptedFiles="image/png, image/jpg, image/jpeg, image/svg+xml"
          buttonIcon={<AddIcon />}
          buttonText="Add"
          imagePath={existingProgramImagePath}
          onChangeFile={(file) => handleImageChange(file)}
          viewMode={isViewing}
          width={464}
        />
        <VSpacer size="4" />
        <VSpacer size="9" />
        <Stack direction="row">
          <Text category="title-medium">
            Program Period
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Select the time period that the retailer rewards program is valid for
          </InfoTooltip>
        </Stack>
        <VSpacer size="6" />
        {DateInputs}
        <VSpacer size="9" />
        <Stack direction="row">
          <Text category="title-medium">
            Segments Included
          </Text>
          <HSpacer size="3" />
          <InfoTooltip>
            Please select only the categories and subcategories that are included within the
            retailer rewards program. When setting up the program tiers, you will establish the
            loyalty value for each. If you deselect a category or subcategory then loyalty points
            will not be granted for products purchased in those segments.
          </InfoTooltip>
        </Stack>
        <VSpacer size="6" />
        {CategorySelect}
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        <Text category="body-xlarge">Points granted (optional)</Text>
        <VSpacer size="3" />
        <Checkbox
          checked={program.grantBothProgramAndPromotionPoints}
          disabled={isViewing}
          onChangeChecked={(checked) => (
            setProgram({ ...program, grantBothProgramAndPromotionPoints: checked })
          )}
          sx={{ marginLeft: '12px' }}
          testID="grant-both-program-and-promotion-points-checkbox"
        >
          <Text category="body-large">
            Grant both rewards program loyalty points AND promotion points on eligible products.
          </Text>
        </Checkbox>
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row">
              <Text category="title-medium">
                Tiers
              </Text>
              <HSpacer size="3" />
              <InfoTooltip>
                Create the rewards tiers each customer can earn through loyalty purchases
                with this retailer
              </InfoTooltip>
            </Stack>
          </Stack>
          {!(isViewing || hasActiveUsers) && !!categories && (
            <Button
              onClick={() => setShowRewardsTiersModal(true)}
              startIcon={program.tiers.length ? <Edit /> : <Add />}
              testID="tiers-button"
              variant="outlined"
            >
              {program.tiers.length ? 'Edit Tiers' : 'Add Tiers'}
            </Button>
          )}
        </Stack>
        <VSpacer size="6" />
        {TierTable}
        <VSpacer size="9" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row">
              <Text category="title-medium">
                Custom Benefits
              </Text>
              <HSpacer size="3" />
              <InfoTooltip>
                Custom benefits are offered to the farmer by the retailer, in addition to tier
                loyalty values, as part of a whole ‘package’ of benefits per tier.
              </InfoTooltip>
            </Stack>
          </Stack>
          {!isViewing && !!program.tiers.length && (
            <Button
              onClick={() => setShowRewardsTierBenefitsModal(true)}
              startIcon={<Add />}
              testID="tiers-button"
              variant="outlined"
            >
              Add Benefit
            </Button>
          )}
        </Stack>
        <VSpacer size="6" />
        <TierBenefitsTable />
      </Modal>
      {showRewardsTiersModal && !!categories && (
        <RewardsTiersModal
          categories={selectedCategories}
          existingTiers={tiersWithSelectedCategories}
          onClose={() => setShowRewardsTiersModal(false)}
          onSave={(tiers) => setProgram({ ...program, tiers })}
        />
      )}
      {showRewardsTierBenefitsModal && !!program.tiers.length && (
        <RewardsTierBenefitModal
          benefit={benefitToEdit}
          onClose={() => setShowRewardsTierBenefitsModal(false)}
          onSave={(benefitSave) => {
            const updatedBenefits = [...benefits];
            if (benefitToEdit) {
              const index = benefits.indexOf(benefitToEdit);
              updatedBenefits[index] = benefitSave;
            } else {
              updatedBenefits.push(benefitSave);
            }
            setBenefits(updatedBenefits);
            setBenefitToEdit(undefined);
          }}
          tiers={tiersWithSelectedCategories}
        />
      )}
      {showDeleteBenefitDialog && benefitToEdit && (
        <DeleteBenefitConfirmDialog
          onCancel={() => setShowDeleteBenefitDialog(false)}
          onConfirm={() => {
            const updatedBenefits =  [...benefits];
            setShowDeleteBenefitDialog(false);
            const index = updatedBenefits.indexOf(benefitToEdit);
            updatedBenefits.splice(index, 1);
            setBenefitToEdit(undefined);
            setBenefits(updatedBenefits);
          }}
          open
        />
      )}
    </>
  );
};
