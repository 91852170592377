import {
  Button,
  Card,
  Chip,
  IconButton,
  ProgressLoader,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { Color } from '@/themes/MUITheme/palette';
import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { ManufacturerApi } from "@/utilities/api/ManufacturerApi";
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

interface PromotionCardProps {
  promotion: ApiPromotion,
  onDelete: () => void,
  onEdit: () => void,
  onView: () => void,
  testID: string,
}

const statusColors: { [key: string]: Color | undefined } = {
  [PromotionStatus.FUTURE]: 'info',
  [PromotionStatus.RUNNING]: 'success',
  [PromotionStatus.EXPIRED]: undefined,
};

function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
}

export const PromotionCard = ({
  onDelete,
  onView,
  promotion,
  testID,
}: PromotionCardProps) => {
  const statuses = getStatuses(promotion);

  const manufacturerId = promotion.manufacturerId;

  const { data: manufacturer, isFetching } = useQuery(
    [QueryKeys.GET_MANUFACTURER_BY_ID, manufacturerId],
    () => manufacturerId ? ManufacturerApi.get(manufacturerId) : null,
    { enabled: !!manufacturerId },
  );

  const isFuture = statuses.some(status => status === PromotionStatus.FUTURE);

  if (isFetching) {
    return (
      <div
        style={{
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <ProgressLoader type='circular' />
      </div>
    );
  }

  const CardContent = (
    <Stack
      alignItems='top'
      direction='row'
      justifyContent={'space-between'}
    >
      <Stack>
        <Text category="overline" sx={{ color: '#D8D8D8' }}>
          {manufacturer?.name}
        </Text>
        <VSpacer size="1" />
        <Text category="body-xlarge">
          {promotion.name}
        </Text>
        <VSpacer size="1" />
        <Text category="body-medium">
          Runs {formatDate(promotion.startDate)} – {formatDate(promotion.endDate)}
        </Text>
      </Stack>
      {isFuture && (
        <Stack alignItems='top' direction='row' spacing={2}>
          <IconButton
            color="inherit"
            onClick={onDelete}
            testID={`${testID}-delete-button`}
          >
            <DeleteOutline />
          </IconButton>
          <Button
            disabled
            onClick={onView}
            size="medium"
            startIcon={<EditIcon />}
            sx={{ padding: '10px 20px' }}
            testID={`${testID}-view-button`}
            variant="outlined"
          >
            Edit
          </Button>
        </Stack>
      )}
    </Stack>
  );

  const StatusChips = (
    <Stack direction="row" gap="8px">
      {statuses.map((status) => (
        <Chip
          color={statusColors[status]}
          key={status}
          label={status.toUpperCase()}
          rounded
          sx={{ fontWeight: 500, height: 20 }}
          testID={`${testID}-status-${status}`}
        />
      ))}
    </Stack>
  );

  return (
    <Card
      cardContentStyle={{ padding: '16px', "&:last-child": { paddingBottom: "16px" } }}
      testID={testID}
    >
      {CardContent}
      <VSpacer size="3" />
      {StatusChips}
    </Card>
  );
};
